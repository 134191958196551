import "./Header.scss";
import { useRef } from "react";


const Header = (props) => {

    const menuRef = useRef(null);
    const closeMenuRef = useRef(null);
    const navigationRef = useRef(null);

    const showCloseMenu = () => {
        console.log(menuRef.current.style.display);

        if (props.isMobile) {
            menuRef.current.style.display = menuRef.current.style.display !== "none" ? "none" : "block";
            closeMenuRef.current.style.display = menuRef.current.style.display !== "none" ? "none" : "block";
            navigationRef.current.style.display = menuRef.current.style.display !== "none" ? "none" : "flex";

        }

    }



    return (
        <section className="header-bar">
            <div className="logo-div">
                <a href="/info">
                    <img className="nav-logo" src="logoOhneRand.png"></img>
                </a>
            </div>
            
            <nav ref={navigationRef} className="navigation">
                <a id="nav-item" href="https://mytickets.kosmos-kuriosum.com/mytickets">Tickets</a>
            </nav>

            {props.isMobile ? <div className="toggleBox">
                <a href="#" onClick={() => showCloseMenu()}>
                    <img ref={menuRef} className="menu-button" src="home-hand.png"></img>
                    <img ref={closeMenuRef} className="close-menu-button" src="close-nav.png"></img>
                </a>

            </div> : null}
        </section>
    )
}

export default Header;