import { useState, useRef } from "react";
import emailjs from 'emailjs-com';
import "./LostAndFound.scss";

const LostAndFound = (props) => {
    const form = useRef();
    
    const [name, setName] = useState("");
    const [item, setItem] = useState("");
    const [iteminfo, setIteminfo] = useState("");
    const [time, setTime] = useState("");
    const [place, setPlace] = useState("");
    const [email, setEmail] = useState("");
    const [buttonText, setButtontext] = useState("Found?!?")

    const [isAppear, setIsAppear] = useState(false);

    const handleLFClick = () => {

        const container = document.getElementById('form_inputs');

        if (container && !isAppear) {
            console.log("!isApper", container.className);
            container.className = "appear";
        }

        if (container && isAppear) {
            console.log("isApper", container.className);
            container.className = "disappear";
        }

        setIsAppear(!isAppear);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(buttonText === "Email sent") {
            return;
        }
        e.disabled = true;
        console.log(form.current);
        emailjs.sendForm('service_6aiu9k7', 'template_njnigdi', form.current, 'user_hvIPgFWdxCPY9MH0TkHt4')
      .then((result) => {
          console.log(result.text);
          setButtontext("Email sent");
          let button = document.getElementById("submit_button");
          button.style.boxShadow = '0px 0px 12px 6px #2eee5e';
          

      }, (error) => {
          console.log(error.text);
      });
        console.log("submitted");
    }
    return (
        <div id={`form_container ${props.isMobile ? "smaller_text" : ""}`} >
            <h2 className={`header ${props.isMobile ? "smaller_text" : ""}`} onClick={() => handleLFClick()}>Lost and Found</h2>
            <div className="form_inputs form-hidden" id="form_inputs" >
                <form ref={form} onSubmit={e => handleSubmit(e)} className="form start">
                    <div className="form_input">
                        <label>Enter your name: </label>
                        <input type="text" name="name" id="name" value={name} onChange={e => setName(e.target.value)} required />
                    </div>
                    <div className="form_input">
                        <label>Enter your email: </label>
                        <input type="email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
                    </div>
                    <div className="form_input">
                        <label>Enter your item: </label>
                        <input type="text" name="item" id="item" value={item} onChange={e => setItem(e.target.value)} required />
                    </div>
                    <div className="form_input">
                        <label>Enter your iteminfo: </label>
                        <input type="text" name="iteminfo" id="iteminfo" value={iteminfo} onChange={e => setIteminfo(e.target.value)} required />
                    </div>
                    <div className="form_input">
                        <label>When did you lose it? </label>
                        <input type="text" name="time" id="time" value={time} onChange={e => setTime(e.target.value)} required />
                    </div>
                    <div className="form_input">
                        <label>Where did you lose it? </label>
                        <input type="text" name="place" id="place" value={place} onChange={e => setPlace(e.target.value)} required />
                    </div>
                    <div className="form_input">
                        <input id="submit_button" type="submit" value={buttonText} />
                    </div>
                </form>
            </div>
        </div>
    );
}


export default LostAndFound;