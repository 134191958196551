import { useEffect } from "react";
import "./Impressum.scss";
import s from "./../Infopage/sketchMainpage";
import p5 from "p5";
import Header from "./../utils/Header/Header";

const Impressum = () => {

    useEffect(() => {
        new p5(s, "impressum_container");
    })

    return(
        <div className="impressum_container" id="impressum_container">
        <Header />
        <h1>Impressum</h1>
        <div>
            <p>Jugend-Kunst-und-Kultur Verein</p>
            <p>Rosiwalgasse 41-43, 1100 Wien</p>
            <p>ZVR-Zahl: 1080895199</p>
            <p>Email:        jukuku.verein@gmail.com </p>
            <p>www.ris.bka.gv.at</p>
            <p>Verbraucher haben die Möglichkeit,
            Beschwerden an die OnlineStreitbeilegungsplattform der EU zu
            richten: http://ec.europa.eu/odr.
            Sie können allfällige Beschwerde auch an
            die oben angegebene E-Mail-Adresse
richten.</p>
        </div>
    </div>
    );
    
}

export default Impressum;