import { useEffect, useState, useRef } from "react";
import LostAndFound from "../LostAndFound/LostAndFound";
import "./Infopage.scss";
import s from "./sketchMainpage.js";
import Header from "./../utils/Header/Header";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import p5 from "p5";



let viewBoxValue = "0 0 7843 7925";



const Infopage = () => {

    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 1000;
    console.log("isMobile", isMobile)
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
        console.log(width);
    }




    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);



    if (isMobile) {
        viewBoxValue = "0 0 100 100";
    }

    useEffect(() => {
        new p5(s, "infoctr_sketch");
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(MotionPathPlugin);



        ScrollTrigger.matchMedia({
            "(min-width: 901px)": function () {

            },
            "(max-width: 900px)": function () {

            }
        });





        if (!isMobile) {
            let path = document.getElementById('svgPath');
            let pathLength = path.getTotalLength();
            path.style.strokeDasharray = pathLength + " " + pathLength;
            path.style.strokeDashoffset = pathLength;

            window.addEventListener("scroll", function () {
                let scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);

                let drawLength = pathLength * scrollPercentage;

                path.style.strokeDashoffset = pathLength - (drawLength * 1.5);
            });
        }
    });




    return (
        <div id="infoctr_sketch">
            <div className="infopage_info">
                <Header isMobile={isMobile} />
                {
                    isMobile ?
                        <svg id="svgPath"></svg> :
                        <div className="line-container">
                            <svg viewBox={viewBoxValue} fill="none" preserveAspectRatio="xMidYMax meet" margin="0">
                                <path margin="0" maxWidth="100vw" height="100%" id="svgPath" d="M3670.04 0.791748L564.575 1569.7L0.791992 4324.18L77.2078 4339.82L633.425 1622.3L3671.96 87.2084L6869.99 1527.9L7756.44 4915.43L5634.93 7686.07L2818.89 7844.27L647.846 6234.96L2082.07 6322.77L4321.84 6757.84L6158 5214.16V2904.47L4224.06 1458.08L1931.03 1962.22L940.473 4279.11L2412.71 3636.07L2417.12 3640.96L3220.05 2916.75L4305.15 3040.76L4923.54 3921.96L4552.09 4974.41L3514.1 5392.7L2538.58 4826.27L2499.42 4893.73L3507.9 5479.3L4613.91 5033.59L5010.46 3910.04L4348.85 2967.24L3193.95 2835.25L2388.96 3561.32L1089.53 4128.89L1986.97 2029.78L4205.94 1541.92L6080 2943.53V5177.84L4300.16 6674.16L2091.93 6245.23L390.155 6141.04L2795.11 7923.73L5675.07 7761.93L7841.56 4932.57L6936.01 1472.1L3670.04 0.791748Z" fill="" stroke="rgba(172, 144, 244, 0.1)" strokeWidth="50" />
                            </svg>
                        </div>
                }
                <div className={`info_text reading_background ${isMobile ? "smaller_text" : ""}`}>
                    <section>

                        <h2 id="winterschlaf">Das Portal des</h2>
                        <h2 id="winterschlaf">Kosmos Kuriosum</h2>
                        <p>brodelt</p>
                    </section>
                </div>
                <div className="ticket_button_class">
                    <a id="ticket_button" target="_blank" href="https://mytickets.kosmos-kuriosum.com/mytickets">
                        TICKETS
                    </a>
                </div>

                <div className="reading_background lost_found">
                    <LostAndFound isMobile={isMobile} />
                </div>
                <div className="privacy_impressum_links">
                    <a href="/privacy">
                        Datenschutzerklärung
                    </a>
                    <a href="/impressum">
                        Impressum
                    </a>
                </div>
                <div className="icons">
                    <a href="https://www.t.me/kosmoskuriosum" target="_blank" className="socialLink"><i className="fab fa-telegram icon" style={{ color: "#0088CC" }}></i></a>
                    <a href="https://www.facebook.com/kosmoskuriosum" target="_blank" className="socialLink"><i className="fab fa-facebook icon" style={{ color: "#3b5998" }}></i></a>
                    <a href="https://www.instagram.com/kosmoskuriosum/" target="_blank" className="socialLink"><i className="fab fa-instagram icon" style={{ color: "#3b5998" }}></i></a>
                </div>
            </div>
        </div>
    );
}

export default Infopage;