import Landing from "./components/Landing/Landing";
import InfoPage from "./components/Infopage/Infopage";
import Datenschutz from "./components/Datenschutz/Datenschutz";
import Impressum from "./components/Impressum/Impressum";


import "./App.scss";
import { Route, BrowserRouter, Routes } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route path="/info" element={<InfoPage />} />
          <Route path="/privacy" element={<Datenschutz />} />
          <Route path="/impressum" element={<Impressum />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
