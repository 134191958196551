import React, { useEffect } from "react";
import p5 from "p5";
import s2 from "./sketch.js";

const Landing = () => {

    useEffect( () => {
        new p5(s2);
    });

    return(
        <div id="landing">
        </div>
    )
}

export default Landing;