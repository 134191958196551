var radius = 250;


let trailInfoObject1 = {
  radius: radius,
  angle: 0,
  speed: 1,
  centerX: 200,
  centerY: 200,
  radiusTrail: 50,
  trailHistory: [],
}

let trailInfoObject2 = {
  radius: radius,
  angle: 100,
  speed: 1.2,
  centerX: 200,
  centerY: 200,
  radiusTrail: 50,
  trailHistory: [],
}

let trailInfoObject3 = {
  radius: radius,
  angle: 200,
  speed: 1.6,
  centerX: 200,
  centerY: 200,
  radiusTrail: 50,
  trailHistory: [],
}

let trailsArray = [trailInfoObject1, trailInfoObject2, trailInfoObject3];


let canvas;

var clicked = false;



//let handSVG;


let bottleSVG;
let dropletSVG;
//let muehleSVG;
//let planetSVG;
let plantSVG;
let outerLogo;

let r1 
let r2 
let g1 
let g2 
let b1
let b2



// variables for Flow Field
let points = [];
let multMin = 0.06;


let createVectors = (trailObjectsArray, sketch) => {
  let newTrailHistoryArray = [];
  for (let i = 0; i < trailObjectsArray.length; i++) {
    let currentTrail = trailObjectsArray[i];

    let middleX = sketch.windowWidth / 2;
    let middleY = sketch.windowHeight / 2;
    let x = middleX + currentTrail.radius * sketch.cos(currentTrail.angle);
    let y = middleY + currentTrail.radius * sketch.sin(currentTrail.angle);
    let v = sketch.createVector(x, y);
    currentTrail.trailHistory.push(v);

    currentTrail.angle = currentTrail.angle + currentTrail.speed;
    currentTrail.x = x;
    currentTrail.y = y;

    if (currentTrail.trailHistory.length > 33) currentTrail.trailHistory.splice(0, 2);
    newTrailHistoryArray[i] = currentTrail.trailHistory;

    trailObjectsArray[i] = currentTrail;
  }

  return newTrailHistoryArray;
}

let s2 = (sketch) => {

  sketch.preload = () => {
    //handSVG = sketch.loadImage("./Logo_deconstruct/hand.svg");
    bottleSVG = sketch.loadImage("./Logo_deconstruct/bottle5.svg");
    dropletSVG = sketch.loadImage("./Logo_deconstruct/droplet3.svg");
    //muehleSVG = sketch.loadImage("./Logo_deconstruct/mühle.svg");
    //planetSVG = sketch.loadImage("./Logo_deconstruct/planet.svg");
    plantSVG = sketch.loadImage("./Logo_deconstruct/plant2.svg");
    outerLogo = sketch.loadImage("./logoOhneRand.png");
    
  }


  sketch.setup = () => {
    canvas = sketch.createCanvas(sketch.windowWidth, sketch.windowHeight);

    sketch.frameRate(60);
    sketch.pixelDensity(1);
    canvas.position(0, 0);
    canvas.style("z-index", "10");
    sketch.cursor(sketch.HAND);

    sketch.angleMode(sketch.DEGREES);
    sketch.noiseDetail(1);

    let density = 78;
    let width = sketch.width;
    let height = sketch.height;
    let space = width / density;

    for (let x = 0; x < width; x += space) {
      for (let y = 0; y < height; y += space) {
        //let p = createVector(x + sketch.random(-10, 10), y + sketch.random(-10, 10));
        let p = sketch.createVector(x + sketch.random(-10, 10), y + sketch.random(-10, 10));
        points.push(p);
      }
    }

    r1 = sketch.random(255);
    r2 = sketch.random(255);
    g1 = sketch.random(180);
    g2 = sketch.random(180);
    b1 = sketch.random(90);
    b2 = sketch.random(90);
  }


  sketch.windowResize = () => {
    sketch.resizeCanvas(sketch.windowWidth, sketch.windowHeight);

  }

  // function sleep(millisecondsDuration) {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, millisecondsDuration);
  //   })
  // }

  sketch.drawFlowField = () => {
    sketch.noStroke();
    sketch.fill(255);
    let width = sketch.width;
    let height = sketch.height;

    for (let i = 0; i < points.length; i++) {
      let distanzMiddle = sketch.dist(width / 2, height / 2, points[i].x, points[i].y);
      let cutoff = 470;
      let r = sketch.map(points[i].x, 0, width, r1, r2);
      let g = sketch.map(points[i].y, 0, height, g1, g2);
      let b = sketch.map(points[i].x, 0, width, b1, b2);
      let alpha = sketch.map(distanzMiddle, 0, cutoff, 255, 0)

      sketch.fill(r, g, b, alpha);

      let angle = sketch.map(sketch.noise(points[i].x * multMin, points[i].y * multMin), 0, 1, 0, 540);

      points[i].add(sketch.createVector(sketch.cos(angle + 1), sketch.sin(angle + 1)));

      if (distanzMiddle < cutoff) {
        sketch.ellipse(points[i].x, points[i].y, 1);
      }

    }
  }

  sketch.mousePressed = () => {
    clicked = true;
  }



 
  let widthThings = 75;


  

  sketch.draw = () => {
    sketch.background(20, 15, 45);
    //sketch.background(outerLogo);
    
    //outerLogo.resize(sketch.windowHeight / 2, 0);
    //outerLogo.resize(sketch.windowHeight * 2, 0);
    sketch.imageMode(sketch.CENTER);
    sketch.cursor(sketch.HAND)


    if (clicked) {
      sketch.push();
      sketch.drawFlowField();
      sketch.pop();
      // middleX = mouseX;
      // middleY = mouseY;
      if (radius > 1) {
        trailsArray.forEach(trail => {
          if(sketch.windowWidth < 1000){
            trail.radius -= 20;
          } else {
            trail.radius -= 5;
          }
          return trail;
        })
        
        if(sketch.windowWidth < 1000){
          widthThings -= 5;
          radius -= 20;
        } else {
          radius -= 5;
        }

        if (radius % 10 === 0) {
          
          widthThings -= 2;
        }
      } else {
        sketch.remove();
        sketch.removeElements();
        window.location.href = "/info";
      }
    }

    let trailHistoryArray = createVectors(trailsArray, sketch);

    for (let i = 0; i < trailHistoryArray.length; i++) {
      let history = trailHistoryArray[i];

      sketch.push();
      sketch.beginShape();
      for (let u = 0; u < history.length; u += 5) {

        let pos = history[history.length - u];

        if (pos !== undefined && pos) {
          sketch.noStroke();
          sketch.fill(255, 204, 0);
          sketch.curveVertex(pos.x, pos.y);
        }
      }
      sketch.endShape();
      sketch.pop();
    }

    sketch.image(outerLogo, sketch.windowWidth / 2, sketch.windowHeight / 2, 300, 300);

    sketch.image(plantSVG, trailInfoObject1.x, trailInfoObject1.y);
    plantSVG.resize(widthThings, 0);

    //sketch.image(handSVG, sketch.mouseX, sketch.mouseY);
    //handSVG.resize(widthHand, 0);

    sketch.image(dropletSVG, trailInfoObject2.x, trailInfoObject2.y);
    dropletSVG.resize(widthThings, 0);

    sketch.image(bottleSVG, trailInfoObject3.x, trailInfoObject3.y);
    bottleSVG.resize(widthThings, 0);
  }
}

export default s2;


