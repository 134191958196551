let pointsMainpage = [];

const isMobile = window.innerWidth <= 1000;

// 89, 150, 47, 1 
let canvasPositionX = 0;

const s = (sketch) => {
    let r1 = sketch.random(87);
    let r2 = sketch.random(90);
    let g1 = sketch.random(148);
    let g2 = sketch.random(151);
    let b1 = sketch.random(44);
    let b2 = sketch.random(47);

    let multMinMainpage = sketch.random(0.015, 0.08);


    let cnv;

    // settings
    let colorModeAlpha = 255;
    let backgroundColor = 'rgba(255, 255, 255, 100)';
    let oldPurpleIshColor = (20, 15, 45);




    sketch.setup = () => {
        cnv = sketch.createCanvas(sketch.windowWidth, sketch.windowHeight);
        sketch.colorMode("RGB", colorModeAlpha);
        cnv.position(0, 0);
        cnv.style("z-index", "-1");
        //const alpha = sketch.random(180, 255);
        sketch.background(backgroundColor);
        sketch.angleMode(sketch.DEGREES);
        sketch.noiseDetail(1);

        let density = sketch.random(150, 220);
        let width = sketch.width;
        let height = sketch.height;
        let space = width / density;

        for (let x = 0; x < width; x += space) {
            for (let y = 0; y < height; y += space) {
                //let p = createVector(x + sketch.random(-10, 10), y + sketch.random(-10, 10));
                let p = sketch.createVector(x + sketch.random(-20, 20), y + sketch.random(-15, 15));
                pointsMainpage.push(p);
            }
        }
    }

    function newPoints() {

        pointsMainpage = [];
        let density = sketch.random(70, 170);
        let width = sketch.width;
        let height = sketch.height;
        console.log("newPoints, width, height:", width, height);
        let space = width / density;

        for (let x = 0; x < width; x += space) {
            for (let y = 0; y < height; y += space) {
                //let p = createVector(x + sketch.random(-10, 10), y + sketch.random(-10, 10));
                let p = sketch.createVector(x + sketch.random(-30, 30), y + sketch.random(-30, 30));
                pointsMainpage.push(p);
            }
        }
    }

    let frameCountToggleColorMode = true;
    let alpha = sketch.random(50, 95);




    sketch.draw = () => {
        if (sketch.frameCount > 40) {
            return;
        }

        if (sketch.frameCount % 400 === 0) {
            newPoints();
        }

        if (!frameCountToggleColorMode) {
            colorModeAlpha -= 1;
            sketch.colorMode("RGB", colorModeAlpha);
        } else if (frameCountToggleColorMode) {
            colorModeAlpha += 1;
            sketch.colorMode("RGB", colorModeAlpha);
        }

        if ((sketch.frameCount % 1117 === 0)) {
            frameCountToggleColorMode = !frameCountToggleColorMode;
        }


        cnv.position(0, canvasPositionX);
        sketch.noStroke();
        sketch.fill(255);


        let width = sketch.width;
        let height = sketch.height;

        for (let i = 0; i < pointsMainpage.length; i += 2) {

            let r = sketch.map(pointsMainpage[i].x, 0, width, r1, r2);
            let g = sketch.map(pointsMainpage[i].y, 0, height, g1, g2);
            let b = sketch.map(pointsMainpage[i].x, 0, width, b1, b2);
            let alpha = sketch.map(sketch.dist(width / 2, height / 2, pointsMainpage[i].x, pointsMainpage[i].y), 0, 360, 255, 0)
            sketch.fill(r, g, b, alpha);

            let angle = sketch.map(sketch.noise(pointsMainpage[i].x * multMinMainpage, pointsMainpage[i].y * multMinMainpage), 0, 1, 0, 1080);
            pointsMainpage[i].add(sketch.createVector(sketch.cos(angle), sketch.sin(angle)));
            sketch.ellipse(pointsMainpage[i].x, pointsMainpage[i].y, 1);

        }
    }

    sketch.windowResized = () => {

        if (!isMobile) {
            sketch.background(backgroundColor);
            sketch.resizeCanvas(sketch.windowWidth, sketch.windowHeight);
        }
        newPoints();
    }
}

export default s;
